import { MenuListApi, RouteListApi } from "@/request/api";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

let routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/HomeView.vue"),
    children: [
      {
        path: "/Error",
        name: "Error",
        component: () => import("../views/Error.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
];

async function get_router() {
  const token = localStorage.getItem("token");
  if (token) {
    console.log("执行了RouteListApi", token);
    let GetUserRoute = await RouteListApi();
    let GetUserMenuList = await MenuListApi();
    if (GetUserRoute) {
      GetUserRoute.data.forEach((item) => {
        routes[0].children.push({
          path: item.path,
          component: () => import("../views" + item.path),
        });
      });
    }
    if (GetUserMenuList) {
      let foundUrlValue = false; // 用于记录是否已经找到符合条件的url_value
      GetUserMenuList.data.forEach((item) => {
        console.log("执行了if111", item);
        if (!foundUrlValue && item.child && item.child.length > 0) {
          routes[0].redirect = item.child[0].url_value; // 设置重定向地址为接口返回的第一个对象里child里的第一个对象的url_value
          foundUrlValue = true;
        }
      });
    }
  }

  return new VueRouter({
    routes,
    mode: "hash",
  });
}

const router = await get_router();

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.path === "/login" && token) {
    next("/");
    return;
  }
  if (to.path !== "/login" && !token) {
    console.log("执行了这aaaaaaaaaaaa");
    //清除本地存储
    localStorage.removeItem("total_shots_count");
    localStorage.removeItem("station_id");
    localStorage.removeItem("token");
    next("/login");
    return;
  }
  next();
});

export default router;
